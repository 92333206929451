import { v4 as uuidv4 } from 'uuid';

import { getPageContext, trackEvent } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import {
  FlowName,
  trackFlowStarted as _trackFlowStarted,
  trackFlowStepCompleted as _trackFlowStepCompleted,
  trackFlowStepViewed as _trackFlowStepViewed,
  trackFlowCompleted as _trackFlowCompleted,
} from '@zola-helpers/client/dist/es/tracking/flow/flowEvents';

import {
  trackOnboardingStarted,
  trackOnboardingStepCompleted,
} from '@zola-helpers/client/dist/es/tracking/onboard/onboardEvents';

export { trackEvent, trackOnboardingStarted, trackOnboardingStepCompleted };
// TODO / NOTE : Most of these events should be added to zola-helpers

export const customEventNames = {
  REGISTRY_ITEM_ADDED: 'Registry Item Added',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  CTA_CLICKED: 'CTA Clicked',
  SELECT_ALL_CLICKED: 'Clicked Select All', // quick add packs detail page
  SHOP_ALL_CLICKED: 'Clicked Shop All', // quick add packs detail page
  PRODUCT_LIST_FILTERED: 'Product List Filtered',
  PUBLIC_REGISTRY_VIEWED: 'Registry Viewed',
};

const FACETS_TRACKING_EVENT_KEY_MAP = {
  PRICE_RANGE: 'facet_price',
  COLOR: 'facet_color',
  BRAND: 'facet_brand',
};

export { uuidv4 as getUUIDv4 };

export function trackPageLoaded(): void {
  if (window.analytics && window.analytics.page) {
    const payload = getPageContext();
    window.analytics.page({ ...payload });
  }
}

export function trackFlowStarted(flowName: FlowName, flowVersion: number, flowRunId: string): void {
  _trackFlowStarted({
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,

    // The registry flows have not sent flow step count.  They should, but
    // that is beyond the scope of the TS conversion and using zola-helpers
    // as the tracking library where this cast / field was added
    flow_step_count: null as unknown as number,
  });
}

export function trackFlowCompleted(
  flowName: FlowName,
  flowVersion: number,
  flowRunId: string
): void {
  _trackFlowCompleted({
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,

    // The registry flows have not sent flow step count.  They should, but
    // that is beyond the scope of the TS conversion and using zola-helpers
    // as the tracking library where this cast / field was added
    flow_step_count: null as unknown as number,
  });
}

export function trackFlowStepViewed(
  flowName: FlowName,
  flowVersion: number,
  flowRunId: string,
  stepName: string,
  stepNumber: number
): void {
  _trackFlowStepViewed({
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,
    step_name: stepName,
    step_number: stepNumber,
  });
}

export function trackFlowStepCompleted(
  flowName: FlowName,
  flowVersion: number,
  flowRunId: string,
  stepName: string,
  stepNumber: number
): void {
  const payload = {
    flow_name: flowName,
    flow_version: flowVersion,
    flow_run_id: flowRunId,
    step_name: stepName,
    step_number: stepNumber,
  };
  _trackFlowStepCompleted(payload);
}

export function trackRecommendation(action: string, recommendationData: unknown): void {
  trackEvent(`CTA Recommendation ${action}`, recommendationData);
}

/**
 * Helper to get category facet data and depth of the current node in category tree
 * @param {Number} nodeId - id of the category tree we get data for
 * @param {Object} categoryTree - category tree object where the key is being looked up in
 * @return {Object} - returns object with selected category key mapped to node name, along
 * with it's patents
 */
export function getCategoryEventDetails(
  nodeId: string,
  categoryTree: any[]
): Record<string, unknown> {
  const categoryDepth = 1;
  const nodeLevels: Record<string, unknown> = {};
  (function findNode(id, tree, i): unknown {
    return tree.find((node) => {
      if (node.id === id) {
        nodeLevels[`facet_category_${i}`] = node.name;
        return node;
      }
      const result = findNode(id, node.children, i + 1);
      if (result) {
        nodeLevels[`facet_category_${i}`] = node.name;
      }
      return result;
    });
  })(nodeId, categoryTree, categoryDepth);
  return nodeLevels;
}

/**
 * Gets facets event data with selected facets options mapped to their labels
 * @param {Object} selectedFacets - facets that are selected
 * @param {Array<Object>} facets - all facets with label information
 * @return {Object} - facet data formatted for event
 *
 * TS Notes: This is a very poor TS conversion
 */
export function getFacetsEventDetails(
  selectedFacets: Record<string, Record<any, any>>,
  facets: any[]
): Record<string, unknown> {
  const facetsEventData: Record<string, unknown> = {};
  Object.keys(selectedFacets).forEach((facetKey) => {
    const eventKey = (FACETS_TRACKING_EVENT_KEY_MAP as Record<string, string>)[facetKey];
    if (eventKey) {
      // find parent facet object with options
      const facet = facets.find((n) => n.key === facetKey);
      // look through options to get labels
      const selectedOptions = Object.keys(selectedFacets[facetKey]);
      facetsEventData[eventKey] =
        facet &&
        facet.options
          .filter((n: { value: string; label: string }) => selectedOptions.indexOf(n.value) > -1)
          .map((n: { label: string }) => n.label);
    }
  });
  return facetsEventData;
}

type HackEventData = Record<string, unknown> & {
  brand?: string;
};

function checkGTMBrand(eventData: HackEventData): void {
  // if the brand is not defined - clear it on the GTM data layer because we check for it to determine if the product added is a cash gift.
  if (!eventData.brand && window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({
      brand: undefined,
    });
  }
}

export function trackProductAdded(eventData: HackEventData): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_ADDED, eventData);
}

export function trackProductClicked(eventData: HackEventData): void {
  if (!eventData) return;
  trackEvent(customEventNames.PRODUCT_CLICKED, eventData);
}

export function trackCtaClicked(eventData: HackEventData): void {
  if (!eventData) return;
  trackEvent(customEventNames.CTA_CLICKED, eventData);
}

export function trackProductViewed(eventData: HackEventData): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_VIEWED, eventData);
}

export function trackRegistryItemAdded(eventData: HackEventData): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.REGISTRY_ITEM_ADDED, eventData);
}

export function trackProductListViewed(eventData: HackEventData): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_LIST_VIEWED, eventData);
}

export function trackSelectAllClicked(eventData: HackEventData): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.SELECT_ALL_CLICKED, eventData);
}

export function trackShopAllClicked(eventData: HackEventData): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.SHOP_ALL_CLICKED, eventData);
}

export const trackProductListFiltered = (payload: Record<string, unknown>): void => {
  const eventData = {
    category: 'Registry',
    filters: [],
    sorts: [],
  };
  trackEvent(customEventNames.PRODUCT_LIST_FILTERED, {
    ...eventData,
    ...payload,
  });
};

export function trackPublicRegistryViewed(registryId: string | null | undefined): void {
  if (!registryId) return;

  trackEvent(customEventNames.PUBLIC_REGISTRY_VIEWED, {
    location: 'REGISTRY_STANDALONE',
    registry_id: registryId,
  });
}
