import { UserContext } from '@/actions/UserActions.type';
import dynamic from 'next/dynamic';
import { useShopContext } from '@zola/zola-ui/src/contexts/ShopContext/ShopContext';

const BabyRegistryProvider = dynamic(() => import('./BabyRegistryProvider'), {
  loading: () => <></>,
});

const WeddingRegistryProvider = dynamic(() => import('./WeddingRegistryProvider'), {
  loading: () => <></>,
});

export const RegistryProvider: React.FC<{ userContext: UserContext | null }> = (props) => {
  const { isBaby } = useShopContext();

  return isBaby ? <BabyRegistryProvider {...props} /> : <WeddingRegistryProvider {...props} />;
};
