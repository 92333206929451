import styled from '@emotion/styled';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  padding: 0 20px;
  border-bottom: 1px solid ${COLORS3.BLACK_030};
  flex: 0 0 auto;
`;
