import styled from '@emotion/styled';
import DropdownListBox from '@zola/zola-ui/src/components/Form/common/DropdownListBox';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

export const FilterDropdownContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const StyledDropdownListBox = styled(DropdownListBox)`
  min-width: 280px;
  width: unset;
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px 0;
    list-style: none;
    > li,
    > div {
      padding: 10px 20px;
      &:hover {
        background-color: ${COLORS3.BLACK_005};
      }
    }
  }
`;

export const ListOption = styled.li<{ selected?: boolean }>`
  color: ${COLORS3.BLACK_100};
  ${(props) => props.selected && `background-color: ${COLORS3.BLACK_005};`}
`;

export const FilterButton = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 16px;
  border-radius: 100px;
  background: ${(props) => (props.active ? 'transparent' : COLORS3.BLACK_005)};
  color: ${COLORS3.BLACK_100};
  font-weight: ${FONT.FONT_WEIGHT_BOLD};
  border: 1px solid ${(props) => (props.active ? COLORS3.BLACK_100 : 'transparent')};
  :hover {
    background: transparent;
    border: 1px solid ${COLORS3.BLACK_100};
  }
  svg {
    margin-left: 8px;
  }
`;
