import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import type {
  WRegistryCollectionItemView,
  WRegistryCollectionSearchView,
  RegistryCollectionItemsSearchRequest,
  UpdateCollectionItemsOrderRequest,
  VoidResponse,
  UpdateRegistryRequest,
} from '@zola/svc-web-api-ts-client';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';
import type { RootState } from '@/store';
import Logger from '@/util/logger';
import type { RegistryItemDetails } from '@/types/RegistryItemDetails';
import {
  getManageRegistryCollections,
  getManageRegistryCollectionItemsId,
} from '@/selectors/manageRegistrySelectors';
import { arrangeCollections } from '@/util/registry';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import type { RegistryType } from '@/types/registry';
import { receivedRegistryBySlug } from './types/RegistryActionTypes';
import { fetchMessagesAndPlanner } from './OverviewActions';

import * as ActionType from './types/ManageRegistryActionTypes';
import { AppThunk } from './types';

export const updateFilters = (key: string, value: string) => ({
  type: ActionType.UPDATE_FILTERS,
  payload: { key, value },
});

type UpdateFiltersAction = ReturnType<typeof updateFilters>;

export const clearFilters = () => ({
  type: ActionType.CLEAR_FILTERS,
});

type ClearFiltersAction = ReturnType<typeof clearFilters>;

export const updateSort = (sort: string) => ({
  type: ActionType.UPDATE_SORT,
  payload: sort,
});

type UpdateSortAction = ReturnType<typeof updateSort>;

export const updateCollectionObjectId = (collectionObjectId: string) => ({
  type: ActionType.UPDATE_COLLECTION_OBJECT_ID,
  payload: collectionObjectId,
});

type UpdateCollectionObjectIdAction = ReturnType<typeof updateCollectionObjectId>;

export const requestRegistryCollection = () => ({
  type: ActionType.REQUEST_REGISTRY_COLLECTION,
});

type RequestRegistryCollectionAction = ReturnType<typeof requestRegistryCollection>;

export const updateRegistryKey = (registryKey: string) => ({
  type: ActionType.UPDATE_REGISTRY_KEY,
  payload: registryKey,
});

type UpdateRegistryKey = ReturnType<typeof updateRegistryKey>;

export const receiveRegistryCollection = (collection: WRegistryCollectionSearchView) => ({
  type: ActionType.RECEIVE_REGISTRY_COLLECTION,
  payload: collection,
});

type ReceiveRegistryCollectionAction = ReturnType<typeof receiveRegistryCollection>;

export const setSelectedRegistryItem = (item: WRegistryCollectionItemView) => ({
  type: ActionType.SET_SELECTED_REGISTRY_ITEM,
  payload: item,
});

type SetSelectedRegistryItemAction = ReturnType<typeof setSelectedRegistryItem>;

export const setEditZolaGiftCard = (editZolaGiftCard: boolean) => ({
  type: ActionType.SET_EDIT_ZOLA_GIFT_CARD,
  payload: editZolaGiftCard,
});

type SetEditZolaGiftCard = ReturnType<typeof setEditZolaGiftCard>;

const receiveUpdatedRegistryItem = (registryItem: WRegistryCollectionItemView) => ({
  type: ActionType.RECEIVE_UPDATED_REGISTRY_ITEM,
  payload: registryItem,
});

type ReceiveUpdatedRegistryItemAction = ReturnType<typeof receiveUpdatedRegistryItem>;

export const searchRegistryCollection: ThunkAction<
  Promise<ReceiveRegistryCollectionAction>,
  RootState,
  unknown,
  AnyAction
> = (dispatch, getState) => {
  const {
    manageRegistry: { registryKey, sort, collectionObjectId, filters },
  } = getState();

  dispatch(requestRegistryCollection());

  // @ts-expect-error sort is not a required param here
  let request: RegistryCollectionItemsSearchRequest = {
    registry_key: registryKey,
    filters,
    flattened_view: true,
    grouped_by_collection: false,
  };

  if (sort) {
    request = { ...request, sort };
  }

  if (collectionObjectId) {
    request = { ...request, collection_object_id: collectionObjectId };
  }

  return ApiService.post<WRegistryCollectionSearchView>(
    '/web-registry-api/v1/registryCollection/search',
    request
  )
    .then((json) => dispatch(receiveRegistryCollection(json)))
    .catch((err) => {
      Logger.error(err.message, err);
      dispatch(toastsActions.negative({ headline: "Sorry we couldn't fetch your registry." }));
      return dispatch(receiveRegistryCollection({}));
    });
};

export type RegistryUpdateRequest = Omit<
  CamelCasedPropertiesDeep<UpdateRegistryRequest>,
  'userObjectId'
>;

export const updateRegistryDetails = (
  updatedDetails: RegistryUpdateRequest
): AppThunk<Promise<void>> => {
  return (dispatch) => {
    return ApiService.post<
      RegistryType,
      Omit<CamelCasedPropertiesDeep<UpdateRegistryRequest>, 'userObjectId'>
    >('/web-registry-api/v1/registry/update', updatedDetails)
      .then((data) => dispatch(receivedRegistryBySlug(data)))
      .then(() => {
        dispatch(toastsActions.positive({ headline: 'Registry details updated' }));
      })
      .catch((error) => {
        Logger.error(error.mesage, error);
      });
  };
};

export const updateRegistryItem = (
  collectionItemId?: string,
  requestPayload?: RegistryItemDetails
): AppThunk<Promise<any>> => {
  return (dispatch, getState) => {
    const { manageRegistry } = getState();

    const body = {
      ...requestPayload,
      collectionId: manageRegistry.collection?.default_collection_object_id,
    };

    return ApiService.put<WRegistryCollectionItemView>(
      `/web-registry-api/v1/registryCollection/item/${collectionItemId}`,
      body
    ).then(() => dispatch(fetchMessagesAndPlanner()));
  };
};

export const swapRegistryItems = (current: number, target: number) => ({
  type: ActionType.SWAP_REGISTRY_ITEMS,
  payload: { current, target },
});

type SwapRegistryItemsAction = ReturnType<typeof swapRegistryItems>;

export const updateCollectionOrder =
  (current: number, target: number): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const state = getState();
    const defaultCollections = getManageRegistryCollections(state);
    const collectionItemsId = getManageRegistryCollectionItemsId(state);

    const collection_items_id = arrangeCollections(defaultCollections, current, target)?.map(
      (item) => item.item_id || ''
    );

    const request: UpdateCollectionItemsOrderRequest = {
      collection_items_id,
    };

    dispatch(swapRegistryItems(current, target));

    return ApiService.post<VoidResponse>(
      `/web-registry-api/v1/registryCollection/${collectionItemsId}/update/order`,
      request
    )
      .then(() => {
        // nothing to do
      })
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(swapRegistryItems(target, current));
        dispatch(toastsActions.negative({ headline: "Sorry - we couldn't reorder your items." }));
      });
  };

const removeRegistryItem = (itemId: string) => ({
  type: ActionType.REMOVE_REGISTRY_ITEM,
  payload: { itemId },
});

type RemoveRegistryItemAction = ReturnType<typeof removeRegistryItem>;

export const deleteRegistryItem =
  (itemId: string): ThunkAction<Promise<void | any>, RootState, unknown, AnyAction> =>
  (dispatch) => {
    return ApiService.delete<WRegistryCollectionSearchView>(
      `/web-registry-api/v1/registryCollection/delete/${itemId}`
    )
      .then(() => dispatch(removeRegistryItem(itemId)))
      .then(() => dispatch(toastsActions.positive({ headline: 'Item successfully removed' })))
      .then(() => dispatch(fetchMessagesAndPlanner()))
      .catch((err) => {
        Logger.error(err.message, err);
        dispatch(toastsActions.negative({ headline: "Sorry we couldn't remove your item." }));
      });
  };

export type ManageRegistryActions =
  | UpdateFiltersAction
  | ClearFiltersAction
  | UpdateSortAction
  | UpdateCollectionObjectIdAction
  | UpdateRegistryKey
  | RequestRegistryCollectionAction
  | ReceiveRegistryCollectionAction
  | SetSelectedRegistryItemAction
  | SetEditZolaGiftCard
  | ReceiveUpdatedRegistryItemAction
  | SwapRegistryItemsAction
  | RemoveRegistryItemAction;
