import { css } from '@emotion/react';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const tab = css`
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding: 32px;
  width: 100%;
`;

export const title = css`
  /* color: @color-black; */
  ${FONT.textSizeCompact(FONT.FONT_SIZE_LARGE)}
  font-family: ${FONT.FONT_FAMILY_SANS_SERIF};
  font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  margin-bottom: 16px;
`;

export const grid = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  ${MEDIA_QUERY.EXCLUDE_MOBILE} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const gridItem = css`
  cursor: pointer;
  transition: 200ms box-shadow;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5);

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    filter: brightness(90%);
  }
`;

export const image = css`
  aspect-ratio: 712 / 258;
  height: 100%;
  width: 100%;
`;
