import { AppDispatch } from '@/store';
import { RegistryType } from '@/types/registry';
import { ApiService } from '@zola-helpers/client/dist/es';
import type { WRegistryCollectionItemViewResponse } from '@zola/svc-web-api-ts-client';

import {
  receivedCollectionInfoByRegistryId,
  receivedCollectionItemByCollectionId,
  receivedRegistryBySlug,
  receivedRegistryInfoByCollectionId,
  receiveRegistry,
  requestCollectionInfoByRegistryId,
  requestCollectionItemByCollectionId,
  requestRegistry,
  requestRegistryBySlug,
  requestRegistryInfoByCollectionId,
} from './types/RegistryActionTypes';

export const updateRegistryDefaultGiftCard =
  (enable: boolean) =>
  (dispatch: AppDispatch): Promise<void> => {
    return ApiService.put<RegistryType>(`/web-registry-api/v1/registry/default-gift-card`, {
      gift_card_enabled: enable,
    }).then((json) => {
      dispatch(receiveRegistry(json));
    });
  };

export const requestRegistryInfoBySlug =
  (slug: string) =>
  (dispatch: AppDispatch): Promise<RegistryType> => {
    dispatch(requestRegistryBySlug());
    return ApiService.get<RegistryType>(`/web-registry-api/v1/registry/slug/${slug}`).then(
      (json) => {
        dispatch(receivedRegistryBySlug(json));
        return json;
      }
    );
  };

export const fetchRegistryCollectionItem =
  (collectionItemId: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(requestCollectionItemByCollectionId());
    return ApiService.get<WRegistryCollectionItemViewResponse>(
      `/web-registry-api/v1/registryCollection/item/${collectionItemId}`
    ).then((json) => {
      dispatch(receivedCollectionItemByCollectionId(json));
    });
  };

export const fetchRegistryInfoByCollectionItemId =
  (collectionItemId: string) =>
  (dispatch: AppDispatch): Promise<RegistryType> => {
    dispatch(requestRegistryInfoByCollectionId());
    return ApiService.get<RegistryType>(
      `/web-registry-api/v1/registryCollection/${collectionItemId}/registry`
    ).then((json) => {
      dispatch(receivedRegistryInfoByCollectionId(json));
      return json;
    });
  };

export const fetchCollectionInfoByRegistryId =
  (registryId: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(requestCollectionInfoByRegistryId());
    return ApiService.get(`/web-registry-api/v1/registry/${registryId}/collection-previews`).then(
      (json) => {
        dispatch(receivedCollectionInfoByRegistryId(json));
      }
    );
  };

export const requestRegistryInfoById =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(requestRegistry());
    return ApiService.get<RegistryType>('/web-registry-api/v1/registry/user-info')
      .then((json) => {
        dispatch(receiveRegistry(json));
      })
      .catch(() => undefined);
  };
