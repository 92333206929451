import moment from 'moment';
import { getLocalStorage, setLocalStorage } from '@zola-helpers/client/dist/es/util/storage';
import _uniq from 'lodash/uniq';
import { getProductCategory } from '../../util/productHelper';
import { formatPrice } from '../../util/currencyFormatter';
import { isProductFree } from '../../util/productPrice';

// used for react-media queries
export const SCREEN_SIZE_XS = '480px';
export const SCREEN_SIZE_MD = '992px';

export const deliverySurchargeCopy =
  'Delivery Surcharge: Due to its weight or perishable nature, this item has an incremental delivery surcharge of';

export const formatDate = (dateString) => {
  if (!dateString) return null;
  return moment.utc(dateString).format('M/D');
};

export const formatExpShippingDate = (expShippingMin, expShippingMax) => {
  const expShipText = 'Expedited Shipping:';
  if (expShippingMin === expShippingMax) {
    return `${expShipText} ${expShippingMin}`;
  }
  return `${expShipText} ${expShippingMin} - ${expShippingMax}`;
};

export const getShippingText = (shipMethod, deliverySurcharge) => {
  if (shipMethod === 'Ground Shipping') {
    return deliverySurcharge ? 'Standard shipping' : 'Free standard shipping';
  }

  return shipMethod;
};

export const getPersonalizedArrivalCopy = () =>
  'If you purchase a personalized item, please allow 1-2 weeks on top of the item’s estimated arrival time to receive your order.';

export const getShipDates = (activeSku) => {
  if (!activeSku) {
    return null;
  }

  const arrivalRange = activeSku.arrival_range;
  if (!arrivalRange) return {};
  const expArrivalRange = activeSku.expedited_arrival_range;
  const freeShippingMin = formatDate(arrivalRange.min);
  const freeShippingMax = formatDate(arrivalRange.max);
  const expShippingMin = expArrivalRange && formatDate(expArrivalRange.min);
  const expShippingMax = expArrivalRange && formatDate(expArrivalRange.max);
  return {
    freeShippingMin,
    freeShippingMax,
    expShippingMin,
    expShippingMax,
  };
};

export function findSwatchByProductLookId(id, swatches) {
  const filteredSwatches = swatches.filter((swatch) => swatch.product_look_id === id);
  return filteredSwatches[0];
}

export function findProductLookById(id, productLooks) {
  const filteredProductLooks = productLooks.filter((productLook) => productLook.id === id);
  return filteredProductLooks[0];
}

export function findSkuIndexById(id, skus) {
  let index = 0;
  skus.forEach((sku, i) => {
    if (sku.id === id) index = i;
  });
  return index;
}

export function buildSkuIdIndexMap(skus) {
  const map = {};
  skus.forEach((sku, i) => {
    map[sku.id] = i;
    map[i] = sku.id;
  });
  return map;
}

export const hidePriceMatchingCopy = (activeProductLook, activeSku, productType) => {
  if (productType === 'EXPERIENCE' || productType === 'GIFT_CARD') return true;
  return isProductFree(activeProductLook, activeSku);
};

/**
 * @typedef Price
 * @property {Number} low_price
 * @property {Number} high_price
 */
/**
 * @typedef ReferencePrice
 * @property {Number} amount
 * @property {String} type
 */

/**
 * @typedef ActiveProductLook
 * @property {Price} reference_price_range
 * @property {Price} price_range
 */
/**
 * @typedef ActiveSku
 * @property {ReferencePrice} reference_price
 * @property {Number} price_cents
 */
/**
 *
 * @param {ActiveProductLook} activeProductLook
 * @param activeSku
 * @returns {({lowPrice: *}|{lowPrice: *, highPrice: *})|Number|Number|Number}
 */
export const getProductPricesSchema = (activeProductLook, activeSku) => {
  const getPriceRange = (range) => {
    if (range.min_price === range.max_price) {
      return {
        lowPrice: range.min_price,
      };
    }
    return {
      lowPrice: range.min_price,
      highPrice: range.max_price,
    };
  };
  if (activeSku && activeSku.reference_price) {
    // determine reference price from SKU
    if (['MSRP', 'COMPAREATPRICE', 'DISCOUNTPRICE'].includes(activeSku.reference_price.type)) {
      return activeSku.reference_price.amount;
    }

    return activeSku.price_cents;
  }
  if (activeProductLook.reference_price_range) {
    // determine reference price range from product look
    return getPriceRange(activeProductLook.reference_price_range);
  }

  return getPriceRange(activeProductLook.price_range);
};

export const getProductViewedEventData = (productData, activeProductLook, activeSku, quantity) => {
  const { brand } = productData;
  const categoryView = getProductCategory(productData);
  const skuToTrack = activeSku || activeProductLook.sku_previews[0]; // we might not have an active SKU at this point, so fall back to the first one
  return {
    product_id: productData.id,
    sku: skuToTrack.id,
    brand: brand.name,
    name: productData.name,
    category: categoryView && categoryView.name,
    variant: activeProductLook.id,
    quantity,
    url: window.location.href,
    price: formatPrice(skuToTrack.price_cents, true), // flag to format for segment
    location: 'PDP',
  };
};

export const getProductAddedEventData = (
  productData,
  activeProductLook,
  activeSku,
  quantity,
  cartId,
  location,
  section,
  extraData
) => {
  const { brand } = productData;
  return {
    product_id: productData.id,
    sku: activeSku.id,
    brand: brand && brand.name,
    name: productData.name,
    variant: activeProductLook.id,
    quantity,
    price: formatPrice(activeSku.price_cents, true), // flag to format for segment
    location: location || 'PDP',
    section: section || 'MAIN_CTA',
    cart_id: cartId,
    ...extraData,
  };
};

export const getProductClickedEventData = (
  productData,
  activeProductLook,
  activeSku,
  quantity,
  location,
  section,
  position
) => {
  const { brand } = productData;
  const eventData = {
    brand: brand && brand.name,
    name: productData.name,
    variant: activeProductLook.id,
    product_id: productData.id,
    quantity,
    location,
    section,
    position,
  };
  if (activeSku) {
    eventData.sku = activeSku.id;
    eventData.price = formatPrice(activeSku.price_cents, true); // flag to format for segment
  } else if (activeProductLook && activeProductLook.price_range) {
    eventData.price = _uniq(Object.values(activeProductLook.price_range))
      .map((n) => n / 100)
      .join(' - ');
  }
  return eventData;
};

export const getKitRegistryItemAddedEventData = ({
  productData,
  activeProductLook,
  activeSku,
  quantity,
  location,
  section,
  extraData = {},
}) => {
  const { brand } = productData;
  const eventData = {
    name: productData.name,
    brand: brand && brand.name,
    quantity,
    location,
    section,
    ...extraData,
  };
  if (activeSku) {
    eventData.sku = activeSku.id;
    eventData.price = formatPrice(activeSku.price_cents, true); // flag to format for segment
  } else if (activeProductLook && activeProductLook.price_range) {
    eventData.price = Object.values(activeProductLook.price_range)
      .map((item) => formatPrice(item, true))
      .join(' - ');
  }
  return eventData;
};

export const getProductListViewedEventData = (products, location, type, endpointVariant) => {
  let section = '';
  switch (type) {
    case 'brand-related':
      section = 'BRAND';
      break;
    case 'frequently-added-together-skus':
      section = 'RECOMMENDATION_FREQUENTLY_ADDED_TOGETHER';
      break;
    case 'also-added-skus':
      section = 'RECOMMENDATION_ALSO_ADDED';
      break;
    default:
      break;
  }
  const productsData = {};
  products.forEach((product, index) => {
    const productLook = product.product_look_views[0];
    const sku = productLook.sku_previews[0];
    productsData[index] = {
      product_id: product.id,
      sku: sku.id,
      product_look_key: productLook.key,
      name: product.name,
      brand: product.brand && product.brand.name,
      price: formatPrice(sku.price_cents, true),
      quantity: product.suggested_quantity,
    };
  });
  return {
    endpoint_variant: endpointVariant || 'N/A',
    location,
    section,
    recommendation_source: type,
    products: productsData,
  };
};

// Sets all attributes of a given sku using a given React useState setter
// Primary use is to pre-select all attributes when only one sku is available for a product look
export const setSkuAttributes = (attributeSetter, product, sku) => {
  if (product.attributes.length > 0) {
    const updatedAttributes = {};
    sku.attribute_values.forEach((attrib) => {
      updatedAttributes[attrib.key] = attrib.value_key;
    });
    attributeSetter(updatedAttributes);
  }
};

// creates a map for all the query params in the url
// currently used for the PDP to determine what breadcrumbs to display
export const getUrlParams = (search = '') => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((acc, hash) => {
    // eslint-disable-next-line
    const [key, val] = hash.split('=');
    return {
      ...acc,
      [key]: decodeURIComponent(val),
    };
  }, {});
};

export const getUrlParameter = (name) => {
  const regexedName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${regexedName}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

// sets the breadcrumbs paramaters in local storage for
// rendering breadcrumbs on the PDP and KDP
export const setBreadcrumbsStorage = (breadcrumbsParams) => {
  if (!breadcrumbsParams) return;
  const kitKey = breadcrumbsParams.kit_key;
  const lookKey = breadcrumbsParams.product_look_key;
  const stringData = getLocalStorage('bcrumbs');
  let data = {};
  if (stringData !== null && stringData !== 'null') {
    data = JSON.parse(stringData);
  }
  if (kitKey) {
    data[kitKey] = breadcrumbsParams;
    setLocalStorage('bcrumbs', JSON.stringify(data));
  } else if (lookKey) {
    data[lookKey] = breadcrumbsParams;
    setLocalStorage('bcrumbs', JSON.stringify(data));
  }
};

export const clearBreadcrumbsStorage = (crumbKey) => {
  if (crumbKey === true) {
    setLocalStorage('bcrumbs', null);
  } else {
    const stringData = getLocalStorage('bcrumbs');
    let data = {};
    if (stringData !== null && stringData !== 'null') {
      data = JSON.parse(stringData);
      data[crumbKey] = null;
      delete data[crumbKey];
      setLocalStorage('bcrumbs', JSON.stringify(data));
    }
  }
};

// retrieves breadcrumbs params from local storage for PDP and KDP
export const getBreadcrumbsStorage = (key) => {
  // if no key check for a web-zola featured registry ref_type
  const ngBreadcrumbs = getLocalStorage('breadcrumbs');
  const params = JSON.parse(ngBreadcrumbs);
  if (params && params.ref_type === 'FEATURED') {
    // setting a timeout to remove breadcrumbs to preserve params for return
    setTimeout(() => {
      setLocalStorage('breadcrumbs', null);
    }, 0);
    return params;
  }
  const bcParams = getLocalStorage('bcrumbs');
  if (bcParams !== null && bcParams !== 'null' && key) {
    const data = JSON.parse(bcParams);
    return data[key];
  }
  return {};
};

// traverses the category tree for a given key and returns a chain of categories from parent to child
export const buildCategoryChainFromKey = (categoryKey, categoryTree, categoryChain) => {
  const results = categoryChain || [];
  // set base category
  if (results.length === 0) {
    results.push({ key: categoryTree.key, name: categoryTree.name });
  }
  if (categoryTree.key === categoryKey) {
    return results;
  }
  // recursively call function for each item in category children
  for (let i = 0; i < categoryTree.children.length; i += 1) {
    const child = categoryTree.children[i];
    const chain = results.slice();
    chain.push({ name: child.name, key: child.key });
    const found = buildCategoryChainFromKey(categoryKey, child, chain);
    if (found) {
      return found;
    }
  }
  return null;
};

export const findFirstImage = (productData) => {
  const productLooks = productData.product_look_views;
  const firstLook = productLooks[0];
  if (!productData || !productLooks || !firstLook) return '';
  const image = firstLook.images && firstLook.images[0].aspect_ratios['1x1'].full;
  return image;
};

const arrivesBeforeChristmas = (maxArrivalRange) => {
  const currentYY = moment().format('YY');
  return moment.utc(maxArrivalRange).isBefore(`12-25-${currentYY}`);
};

export const returnHolidayShippingMessage = (productData, freeShippingMin, activeSku = {}) => {
  // BE ref for Holiday deliveries => https://github.com/NewAmsterdamLabs/svc-web-api/blob/development/server/src/main/java/com/zola/service/webapi/core/util/HolidayUtil.java#L67

  const isEDelivery = productData?.ship_method === 'E-delivery';
  const {
    order_by: standardHolidaysShippingDate,
    order_by_expedited: expeditedHolidaysShippingDate,
    show_expedited: showExpeditedHolidaysShippingDate,
  } = productData?.holiday_shipping || {};

  if (!isEDelivery && !freeShippingMin) {
    return null;
  }

  /*
   * If no Holidays shipping is possible, abort
   * Standard shipping method is always returned
   */
  if (!standardHolidaysShippingDate) {
    return null;
  }

  /*
   * All Merch Holidays shipping logic
   * for both Standard and Expedited shipping methods
   */
  // Standard is always returned
  const shipByDateStandard = moment.utc(standardHolidaysShippingDate);
  const shipByDateStandardFormatted = shipByDateStandard.format('M/D');
  // If expedited is available, it will be returned
  const shipByDateExpedited = moment.utc(expeditedHolidaysShippingDate) || null;
  const shipByDateExpeditedFormatted = shipByDateExpedited?.format('M/D');

  // For experience/e-card products only
  if (isEDelivery) {
    return `We recommend ordering by ${shipByDateStandardFormatted}.`;
  }

  // For merchandise products only
  if (arrivesBeforeChristmas(activeSku?.arrival_range?.max)) {
    // If the standard shipping is still possible as compared to the current date
    // + If expedited shipping is available for the current item
    if (shipByDateStandard.isAfter()) {
      return showExpeditedHolidaysShippingDate && !!shipByDateExpedited
        ? `We recommend ordering by ${shipByDateStandardFormatted}, or ${shipByDateExpeditedFormatted} for expedited.`
        : `We recommend ordering by ${shipByDateStandardFormatted}.`;
    }
    // If the standard shipping is beyond the current date BUT the expedited option is available
    if (
      showExpeditedHolidaysShippingDate &&
      !!shipByDateExpedited &&
      shipByDateExpedited.isAfter()
    ) {
      return `We recommend ordering by ${shipByDateExpeditedFormatted} for expedited.`;
    }
  }

  // Can't arrive on time
  return null;
};
