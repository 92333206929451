import type { AddToRegistryRequest } from '@/actions/RegistryCollectionActions';
import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';
import { createContext, useContext } from 'react';

type RegistryContextValue = {
  hasRegistry: boolean;
  registryId: string | null;
  registrySlug: string | null;

  /**
   * Adds an item to the registry.  This does not handle the registry not being
   * setup yet.  An check must be made to determine if the registry has been created
   * and if not, redirect to onboarding to create the registry.
   */
  addToRegistry: (
    data: AddToRegistryRequest,
    productShippingZones?: string[],
    productShippingExcludedStates?: string[]
  ) => Promise<WRegistryCollectionItemView | undefined>;

  /**
   * Takes the pre-auth or post-auth account (without a registry) to the onboarding
   * flow to either create their account or setup their registry.
   */
  redirectToOnboarding: () => void;

  /** For an existing user, who does not have this type of registry yet, create their registry */
  createRegistryForExistingUser: () => Promise<void>;
};

export const RegistryContext = createContext<RegistryContextValue | undefined>(undefined);

export const useRegistryContext = (): RegistryContextValue => {
  const context = useContext(RegistryContext);
  if (!context) {
    throw new Error('useRegistryContext must be used within a Registry Provider');
  }
  return context;
};
