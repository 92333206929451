import { UserContext } from '@/actions/UserActions.type';
import { ThemeSelector } from '@/components/shop/ThemeSelector/ThemeSelector';
import { ShopProvider } from '@zola/zola-ui/src/contexts/ShopContext/ShopProvider';
import { RegistryProvider } from './RegistryContext/RegistryProvider';

export const RegistryPage: React.FC<{
  isBaby: boolean;
  userContext: UserContext | null;
}> = ({ isBaby, userContext, children }) => {
  return (
    <ShopProvider store={isBaby ? 'baby' : 'wedding'}>
      <ThemeSelector>
        <RegistryProvider userContext={userContext}>{children}</RegistryProvider>
      </ThemeSelector>
    </ShopProvider>
  );
};
