import styled from '@emotion/styled';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

export const DiscountText = styled.span`
  color: ${COLORS3.ROSE_100};
  display: block;
  font-size: ${FONT.FONT_SIZE_SMALLER};
  line-height: ${FONT.FONT_SIZE_SMALL};
  margin: 0 0 5px 0;
  text-wrap: wrap;
`;

export const PriceOriginal = styled.span`
  align-items: center;
  display: flex;

  [data-tooltip]::after {
    font-size: 12px;
    line-height: 17px;
    margin-left: -90px;
    text-decoration: none;
    white-space: normal;
    width: 180px;
  }
`;

export const Price = styled.span<{ isDiscounted: boolean }>`
  color: ${(props) => (props.isDiscounted ? COLORS3.ROSE_100 : 'inherit')};
  display: block;
  font-size: ${FONT.FONT_SIZE_SMALL};
`;

export const MsrpTooltip = styled.span`
  display: flex;
  padding: 0 0 0 4px;
`;

export const PriceStrokeThrough = styled.s`
  margin: 0 0 0 4px;
`;
