import { BaseSyntheticEvent } from 'react';

import { QuoteIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Quote';
import { ArrowsMoveIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ArrowsMove';
import { TrashIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Trash';
import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import { RegistryTileTags } from './RegistryTileTags';
import { getTileImage } from './utils';
import {
  TileImageContainer,
  TileImage,
  QuoteContainer,
  QuoteWrapper,
  Quote,
  QuoteButton,
  TileEditActions,
  IconButton,
  MoveButton,
} from './RegistryTileImage.styles';

interface RegistryTileImageProps {
  item: WRegistryCollectionItemView;
  editable?: boolean;
  draggable?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
}

export const RegistryTileImage: React.FC<RegistryTileImageProps> = ({
  item,
  editable,
  draggable,
  onDelete,
}) => {
  const tileImage = getTileImage(item);
  const note = item.personal_note;
  const isScheduledImport = item.scheduled_import;

  const handleDeleteItem = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof onDelete === 'function') {
      return onDelete();
    }

    return null;
  };

  return (
    <TileImageContainer>
      <TileImage src={tileImage} alt={item.name} />
      {editable && (
        <TileEditActions>
          {draggable && (
            <MoveButton variant="secondary">
              <ArrowsMoveIcon height={20} width={20} />
            </MoveButton>
          )}
          {!isScheduledImport && (
            <IconButton variant="secondary" onClick={handleDeleteItem}>
              <TrashIcon height={20} width={20} />
            </IconButton>
          )}
        </TileEditActions>
      )}
      <RegistryTileTags item={item} />
      {note && (
        <QuoteContainer>
          <QuoteButton>
            <QuoteIcon />
          </QuoteButton>
          <QuoteWrapper>
            <Quote data-testid="tile-guest-message">{note}</Quote>
          </QuoteWrapper>
        </QuoteContainer>
      )}
    </TileImageContainer>
  );
};
