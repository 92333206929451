import { Fragment } from 'react';
import {
  PRICING_TYPES,
  DISCOUNT_TYPES,
  PricingStrikeThroughProps,
} from '@/components/common/PricingStrikeThrough/types';
import { MsrpTooltip } from '@/components/common/PricingStrikeThrough/PricingStrikeThrough.styles';
import { InfoCircleIcon } from '@zola/zola-ui/src/components/SvgIconsV3/InfoCircle';
import { convertCurrencyFormat } from '@/util/currencyFormatter';

const MSRP_COPY = `"MSRP" refers to the manufacturer's suggested retail price, which may not be the prevailing market price or the regular retail price.`;

export const getPriceFractionDigits = (price: number): number => ((price / 100) % 1 > 0 ? 2 : 0);

export const getOriginalPriceLabel = (
  discountType?: PRICING_TYPES
): string | React.ReactFragment => {
  let label;

  if (discountType === PRICING_TYPES.MSRP) {
    label = (
      <Fragment>
        MSRP{' '}
        <MsrpTooltip data-tooltip={MSRP_COPY}>
          <InfoCircleIcon />
        </MsrpTooltip>
      </Fragment>
    );
  }

  return label || 'Was';
};

export const getPriceLabel = (pricingType: DISCOUNT_TYPES.COMPLETION | PRICING_TYPES): string => {
  switch (pricingType) {
    case PRICING_TYPES.DISCOUNTED:
      return `Sale `;
    case PRICING_TYPES.MSRP:
      return `Our Price `;
    default:
      return '';
  }
};

export const formatPromoMessage = (promoData: PricingStrikeThroughProps['promoData']) => {
  if (!promoData) return '';

  const promoDiscountAmount = promoData.discount_amount || 0;
  const promoDiscountMinimumSpend = promoData.minimum_spend || 0;
  const priceInDollar = convertCurrencyFormat(promoDiscountAmount / 100, 'USD', 0, 0);
  if (promoDiscountMinimumSpend > 0) {
    const minPriceInDollar = convertCurrencyFormat(promoDiscountMinimumSpend / 100, 'USD', 0, 0);
    return promoData.discount_type === DISCOUNT_TYPES.PERCENT_OFF
      ? `${promoDiscountAmount}% Off ${minPriceInDollar}+ With Code ${promoData.code}`
      : `${priceInDollar} Off ${minPriceInDollar}+ With Code ${promoData.code}`;
  }

  return promoData.discount_type === DISCOUNT_TYPES.PERCENT_OFF
    ? `${promoDiscountAmount}% Off With Code`
    : `${priceInDollar} Off With Code`;
};

export const getRangePrices = (priceRange: PricingStrikeThroughProps['priceRange']) => {
  const rangePriceMin = priceRange?.min_price || 0;
  const rangePriceMax = priceRange?.max_price || 0;

  const lowerBoundPrice = convertCurrencyFormat(
    rangePriceMin / 100,
    'USD',
    getPriceFractionDigits(rangePriceMin),
    2
  );
  const upperBoundPrice =
    rangePriceMin < rangePriceMax
      ? convertCurrencyFormat(rangePriceMax / 100, 'USD', getPriceFractionDigits(rangePriceMax), 2)
      : '';

  return {
    lowerBoundPrice,
    upperBoundPrice,
  };
};
