import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { ButtonWrapper } from '@zola/zola-ui/src/components/ButtonV3/ButtonV3.styles';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const TileEditActions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  row-gap: 8px;
  right: 8px
  bottom: 8px;

  ${MEDIA_QUERY.DESKTOP} {
    display: none;

    right: 8px;
    top: 8px;
    bottom: unset;
  }
`;

export const QuoteContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`;

export const QuoteWrapper = styled.div`
  position: relative;
  transition: max-height ease 0.5s;
  max-height: 0;
  overflow: hidden;
`;

export const Quote = styled.div`
  background-color: ${COLORS3.CLOUD_100};
  font-size: ${FONT.SIZE.EXTRA_SMALL};
  padding: 8px;
  padding-top: 20px;
`;

export const IconButton = styled(ButtonWrapper)<{ mobileOnly?: boolean }>`
  display: ${(props) => (props.mobileOnly ? 'flex' : 'none')};
  padding: 0;
  border: none;
  height: 44px;
  width: 44px;
  ${MEDIA_QUERY.DESKTOP} {
    display: ${(props) => (props.mobileOnly ? 'none' : 'flex')};
  }
`;

export const MoveButton = styled(IconButton)`
  cursor: move;
`.withComponent('div');

export const QuoteButton = styled(ButtonV3)`
  position: absolute;
  width: 36px;
  height: 36px;
  padding: 0;
  left: 50%;
  transform: translate(-50%, -90%);
  z-index: 1;
  transition: transform ease 0.5s;
`;

export const TileImageContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  &:hover {
    ${TileEditActions} {
      display: flex;
    }
  }
  ${MEDIA_QUERY.DESKTOP} {
    &:hover {
      ${QuoteButton} {
        transform: translate(-50%, -50%);
      }
      ${QuoteWrapper} {
        max-height: 200px;
      }
    }
  }
`;

export const TileImage = styled.img`
  display: block;
  border-radius: 16px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  pointer-events: none;
`;
