import styled from '@emotion/styled';
import { DiscountText } from '@/components/common/SimpleTile/SimpleTilePriceContent.styles';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import { RefPriceContainer, Price, ProgressBarContainer } from './RegistryTilePrice.styles';

export const TileActions = styled.div<{ showAction?: boolean }>`
  display: ${(props) => (props.showAction ? 'flex' : 'none')};
  column-gap: 12px;
  > button {
    flex: 1 1 auto;
  }

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    row-gap: 8px;
  }
`;

export const ItemName = styled.div`
  font-weight: ${FONT.FONT_WEIGHT_BOLD};
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
`;

export const TileContainer = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 100%;
  min-width: 0px;
  min-height: 340px;
  padding: 8px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--zola-grid-border-color, ${COLORS3.BLACK_030});
  border-right: 1px solid var(--zola-grid-border-color, ${COLORS3.BLACK_030});
  display: flex;
  flex-direction: column;
  position: relative;

  font-size: ${FONT.FONT_SIZE_SMALLER};
  color: ${COLORS3.BLACK_100};

  strong {
    font-weight: ${FONT.FONT_WEIGHT_BOLD};
  }

  ${MEDIA_QUERY.DESKTOP} {
    padding: 0;
    padding-bottom: 40px;
    border: none;
    font-size: ${FONT.FONT_SIZE_SMALL};
    min-height: 535px;
  }

  ${MEDIA_QUERY.DESKTOP} {
    &:hover {
      ${({ isActive }) =>
        isActive &&
        `
      ${TileActions} {
        display: flex;
        margin-top: 16px;
      }
      ${RefPriceContainer}, ${Price}, ${ProgressBarContainer} {
        display: none;
      }
      ${DiscountText} {
        visibility: hidden;
      }
    `}
    }
  }
`;

export const BrandName = styled.div`
  height: 23px;
  color: ${COLORS3.BLACK_050};
`;
