import styled from '@emotion/styled';
import { StarFilledInIcon } from '@zola/zola-ui/src/components/SvgIconsV3/StarFilledIn';
import { COLOR_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colors';

export const TileTags = styled.div`
  position: absolute;
  top: 12px;
  left: 8px;
  svg {
    margin-right: 4px;
  }
`;

export const StyledStartFilledInIcon = styled(StarFilledInIcon)`
  color: ${COLOR_SEMANTIC.WARNING_PRIMARY};
`;
