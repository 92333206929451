import styled from '@emotion/styled';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';

export const Footer = styled.div`
  flex: 0 0 auto;

  padding: 12px 20px;
  border-top: 1px solid ${COLORS3.BLACK_030};
  background-color: white;

  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 24px;
`;

export const ClearAll = styled.span`
  font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  font-size: ${FONT.FONT_SIZE_SMALLER};
  line-height: 20px;
  text-decoration-line: underline;
`;
