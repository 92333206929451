import dynamic from 'next/dynamic';
import { useShopContext } from '@zola/zola-ui/src/contexts/ShopContext/ShopContext';

const BabyTheme = dynamic(() => import('./BabyTheme'), {
  loading: () => <></>,
});

const RegistryTheme = dynamic(() => import('./RegistryTheme'), {
  loading: () => <></>,
});

export const ThemeSelector: React.FC = ({ children }) => {
  const { isBaby } = useShopContext();

  return (
    <>
      {isBaby && <BabyTheme />}
      {!isBaby && <RegistryTheme />}
      {/* Render children immediately! */}
      {children}
    </>
  );
};
