import styled from '@emotion/styled';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const ManageRegistryTilesContainer = styled.div`
  margin: 0 -20px;
  border-top: 1px solid var(--zola-grid-border-color, ${COLORS3.BLACK_030});
  ${MEDIA_QUERY.DESKTOP} {
    margin: 0;
    border-top: none;
  }
`;
