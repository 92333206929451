import styled from '@emotion/styled';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const DesktopFiltersContainer = styled.div`
  display: none;

  ${MEDIA_QUERY.DESKTOP} {
    display: block;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
`;

export const SortContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
